import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

      <div className="page_header">
         <h2 className="sub_title">20 X 20 / 下半場</h2>
         <h1 className="main_title">李萬鏗</h1>
      </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_5">
              <div className="context">
                <p>跨領域的藝術工作者李萬鏗是三明治工作室的共同創立人，三明治工他們在研究所時候，開始在樂山教養院的藝術計畫。幾年下來，看到身心障礙者、社工和家庭成員在過程中建立了更好的關係和認同，是很鼓舞人心的事情。透過分享其工作室藝術參與的五個案例經驗來思考公共藝術參與的可能。現今社會當中，弱勢團體常常被排除在公共之外，而三明治工透過藝術參與，讓弱勢團體參與社會，進而引發大眾思考公共的概念，並建立起人與人之間的連結。</p>

                <h5>第一個案例：極速傳說──城市障礙賽</h5>
                <p>透過改裝壞掉的輪椅輔具，製作展覽車，透過從生活感的角度切入，讓觀眾對無障礙議題產生感覺。</p>
                <p>在松山菸場進行無障礙空間的展覽體驗。將生活中遇到的路況，包括許多聲稱為「無障礙」的環境，設計成遙控車障礙賽車場。不只是輪椅，嬰兒車與運送貨推車也都時常使用這些無障礙空間，而透過如此遊戲體驗的方式，讓大家注意到這些糟糕的路況設計。</p>

                <h5>第二個案例：呼吸杯──一口氣，呼吸彼此的生命</h5>
                <p>以呼吸為主題，用生產過程中的每口呼吸展現生命的旅程，讓漸凍人跟社會之間有所連結。他們透過一個玻璃杯，把三口呼吸氣息結合在一起，讓無法動彈只剩呼吸能力的漸凍人病友能夠說出自己的生命故事，能夠參與社會。玻璃透過加熱塑形，慢慢冷卻失去動能，符合病友的生命狀態，與漸凍患者有微妙的呼應。這次的嘗試，讓每個人能夠出現在最適合的位置，去講自己的故事，串連不同人的故事，讓不同的生命故事互相交織，這是共融理念(inclusiveness)的重要價值。</p>
                <p>第一口氣：病友的一口氣，用鏡頭記錄病友在呼吸時的水氣輪廓，製成杯底的圖案。<br/>第二口氣：生產者的一口氣，玻璃工藝師用口吹的方式把玻璃杯吹出成形。<br/>第三口氣：使用者在喝水時留下的一口氣，成為以呼吸串連人與人的一個行為，在日常生活中使用時，會不經意的看到杯底的呼吸，並從呼吸想到人與人的連結。</p>

                <h5>第三個案例：景化公園改造</h5>
                <p>除了滿足無障礙的需求，還希望有特色。三明治工因此製作了一個空間很開放的遊具，以飛碟為主題，讓小孩在飛碟發出的神秘光束中玩耍，也讓身障小孩能夠親近遊具，躲藏空間也方便輪椅轉彎。</p>
                <p>拍照在這年代是一個重要的遊戲經驗，可以展現豐富的想像力，也是很好的宣傳方式，所以在公園入口處的遊具說明牌上，製作一個飛碟穿越公園的假新聞影像，家長可以拍出飛碟吸走小孩的照片，上傳到個人媒體。</p>

                <h5>第四個案例：無限機場</h5>
                <p>共融成為事實並找到想像與現實之間的路徑，不是簡單的事情。去年參與台灣第一個共融藝術節，以室內遊戲場為方向來策劃，但是共融是什麼，障礙是什麼，有什麼是我們都經驗過，又可以一起玩的呢？飛行，這是所有人共同的障礙，但是透過發明和設計，能夠讓每個人都飛起來。展場中有不同的關卡要克服，也有不同的輔具供大家使用──發射器、眼罩、易讀手冊等，讓大家成功摺出並彩繪自己的飛機。</p>

                <h5>第五個案例：選舉與公投的易讀手冊</h5>
                <p>受到台灣智障者家長總會的推薦，製作第一本介紹選舉與公投的易讀手冊，保障身心障礙者參與政治的權益，甚至也有較複雜的地方選舉流程圖。</p>
                <p>三明治工從畫一張畫開始到自行策劃一個展覽，透過參與去累積經驗與能力，堆疊了想像更匯聚了參與和改變社會的能量。 </p>
              </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_1_9" className="next_page">
            <div className="next_title">蔡淳任</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
